import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

export interface GetResponseI {
  name?: string;
  campaign: {
    campaignId: string;
  };
  email: string;
}

@Injectable({
  providedIn: 'root',
})
export class GetResponseService {
  readonly tenantId = environment.tenantId;

  constructor(private http: HttpClient) {}

  addContact(payload: GetResponseI): Observable<any> {
    return this.http.post<GetResponseI>(
      `/strapi-proxy/${this.tenantId}/gr/add-contact`,
      payload
    );
  }

  updateContact(payload: any) {
    return this.http.post<GetResponseI>(
      `/strapi-proxy/${this.tenantId}/gr/update-contact`,
      payload
    );
  }

  pageVisitUtm(payload: any) {
    return this.http.post<GetResponseI>(
      `/strapi-proxy/${this.tenantId}/gr/page-visit-utm`,
      payload
    );
  }

  pageVisit(payload: any) {
    return this.http.post<GetResponseI>(
      `/strapi-proxy/${this.tenantId}/gr/page-visit`,
      payload
    );
  }

  getContact(payload: any) {
    return this.http.get<any>(
      `/strapi-proxy/${this.tenantId}/gr/get-contact/`,
      {
        params: {
          email: payload.email,
        },
      }
    );
  }

  startSession(payload: any) {
    return this.http.post<any>(
      `/strapi-proxy/${this.tenantId}/gr/start-session`,
      payload
    );
  }

  endSession(payload: any) {
    return this.http.post<any>(
      `/strapi-proxy/${this.tenantId}/gr/end-session`,
      payload
    );
  }
}
